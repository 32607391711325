import React, { useEffect, useState } from 'react'
import reactLogo from '@assets/homepage/tech/react.png'
import vueLogo from '@assets/homepage/tech/vue.png'
import angularLogo from '@assets/homepage/tech/angular.png'
import jsLogo from '@assets/homepage/tech/js.png'

type TechType = {
  name: string
  title: string
  logo: string
  logoWidth: number
  logoHeight: number
  richTitle: JSX.Element
  richContent: JSX.Element
}

const TECHS: TechType[] = [
  {
    name: 'react',
    title: 'React',
    logo: reactLogo,
    logoWidth: 42,
    logoHeight: 36,
    richTitle: (
      <>
        Built for <span style={{ color: '#82d7f7' }}>React</span>
      </>
    ),
    richContent: (
      <>
        <p className='hp-section-desc'>
          FullCalendar provides a highly performant React component that accepts JSX for rendering
          nested content
        </p>
        <a className='hp-button hp-button--yellow' href='/docs/react'>
          Learn more
        </a>
      </>
    ),
  },
  {
    name: 'vue',
    title: 'Vue',
    logo: vueLogo,
    logoWidth: 38,
    logoHeight: 33,
    richTitle: (
      <>
        Built for <span style={{ color: '#6de6a0' }}>Vue</span>
      </>
    ),
    richContent: (
      <>
        <p className='hp-section-desc'>
          FullCalendar provides a highly performant Vue component that accepts slot templates for
          nested content
        </p>
        <a className='hp-button hp-button--yellow' href='/docs/vue'>
          Learn more
        </a>
      </>
    ),
  },
  {
    name: 'angular',
    title: 'Angular',
    logo: angularLogo,
    logoWidth: 36,
    logoHeight: 38,
    richTitle: (
      <>
        Built for <span style={{ color: '#ff717e' }}>Angular</span>
      </>
    ),
    richContent: (
      <>
        <p className='hp-section-desc'>
          FullCalendar provides a highly performant Angular component that accepts templates for
          nested content
        </p>
        <a className='hp-button hp-button--yellow' href='/docs/angular'>
          Learn more
        </a>
      </>
    ),
  },
  {
    name: 'js',
    title: 'JavaScript',
    logo: jsLogo,
    logoWidth: 34,
    logoHeight: 34,
    richTitle: (
      <>
        Built for <span style={{ color: '#fff39b' }}>JavaScript</span>
      </>
    ),
    richContent: (
      <>
        <p className='hp-section-desc'>
          FullCalendar's vanilla JavaScript API is blazing fast, using a very small embedded virtual
          DOM library called Preact
        </p>
        <a className='hp-button hp-button--yellow' href='/docs/getting-started'>
          Learn more
        </a>
      </>
    ),
  },
]

const AccordionTechSelector: React.FC<{ tech: string }> = ({ tech: initialTechName }) => {
  const [selectedHash, setSelectedHash] = useState({
    [initialTechName]: true,
  })

  return (
    <div className='hp-tech-accordion'>
      {TECHS.map((techObj, i) => {
        const isSelected = selectedHash[techObj.name]

        return (
          <div className='hp-tech-accordion__item' key={techObj.name}>
            <div
              className='hp-tech-accordion__item-head'
              role='button'
              // tabindex={0} // disable for now, for outline issue https://stackoverflow.com/questions/50465564/tabindex-outlines-only-with-tab-button
              aria-selected={isSelected}
              onClick={() => {
                setSelectedHash({
                  ...selectedHash,
                  [techObj.name]: !isSelected,
                })
              }}
            >
              <span
                className={
                  'hp-tech-accordion__item-toggle' +
                  (isSelected ? ' hp-tech-accordion__item-toggle--selected' : '')
                }
              />
              {renderIcon(techObj, isSelected && i === 0) /* only looks good on first item */}
              {isSelected ? (
                <span className='hp-section-title' style={{ margin: 0 }}>
                  {techObj.richTitle}
                </span>
              ) : (
                <span className='hp-tech-title'>{techObj.title}</span>
              )}
            </div>
            {isSelected && (
              <div className='hp-tech-accordion__item-body' role='region'>
                {techObj.richContent}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const renderIcon = (techObj: TechType, isSelected: boolean) => {
  return (
    <span
      className={'hp-tech-icon hp-tech-icon--small' + (isSelected ? ' hp-tech-icon--selected' : '')}
    >
      <img
        src={techObj.logo}
        alt={`${techObj.title} logo`}
        width={techObj.logoWidth}
        height={techObj.logoHeight}
      />
    </span>
  )
}

type SplitTechSelectorProps = {
  tech: string
  setTech: (techName: string) => void
}

const SplitTechSelector: React.FC<SplitTechSelectorProps> = ({ tech, setTech }) => {
  const techHash = hashTechs()
  const currentTechObj = techHash[tech]

  return (
    <div className='hp-split'>
      <div className='hp-space-verbage' role='tabpanel'>
        <div className='hp-space-verbage__item' key={currentTechObj.name}>
          {/* key fully removes node, for animation */}
          <h2 className='hp-section-title'>{currentTechObj.richTitle}</h2>
          {currentTechObj.richContent}
        </div>
      </div>
      <div className='hp-space-selector'>
        {TECHS.map((techObj) => {
          const isCurrent = techObj.name === tech

          return (
            <div
              className='hp-space-selector__item'
              key={techObj.name}
              role='tab'
              // tabindex={0} // disable for now, for outline issue https://stackoverflow.com/questions/50465564/tabindex-outlines-only-with-tab-button
              aria-expanded={isCurrent}
              onClick={() => {
                setTech(techObj.name)
              }}
            >
              <span className={'hp-tech-icon' + (isCurrent ? ' hp-tech-icon--selected' : '')}>
                <img
                  src={techObj.logo}
                  alt={`${techObj.title} logo`}
                  width={techObj.logoWidth}
                  height={techObj.logoHeight}
                />
              </span>
              <span className={'hp-tech-title' + (isCurrent ? ' hp-tech-title--selected' : '')}>
                {techObj.title}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const hashTechs = () => {
  const hash: { [key: string]: TechType } = {}

  for (const tech of TECHS) {
    hash[tech.name] = tech
  }

  return hash
}

type TechSelectorProps = {
  tabletBreakpoint: number
  tech: string
  setTech: (tech: string) => void
}

export const TechSelector: React.FC<TechSelectorProps> = ({ tabletBreakpoint, tech, setTech }) => {
  const [windowWidth, setWindowWidth] = useState<number>(tabletBreakpoint)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize, { passive: true })
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowWidth < tabletBreakpoint ? (
    <AccordionTechSelector tech={tech} />
  ) : (
    <SplitTechSelector tech={tech} setTech={setTech} />
  )
}
