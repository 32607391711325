import React from 'react'
import balloonBig from '@assets/homepage/balloon-big.svg'
import balloonSmall1 from '@assets/homepage/balloon-small1.svg'
import balloonSmall2 from '@assets/homepage/balloon-small2.svg'
import balloonSmall3 from '@assets/homepage/balloon-small3.svg'

export const Sky = (): JSX.Element => {
  return (
    <section className='hp-sky-section hp-section'>
      <div className='hp-section__inner'>
        <div className='hp-sky-section__color-bg hp-section__bg hp-skew'></div>
        <div className='hp-sky-section__image-bg hp-section__bg'>
          <div className='hp-sky-section__lightning'></div>
        </div>
        <div className='hp-section__fg'>
          <div className='container container--always-spacious'>
            <div className='hp-split'>
              <div className='hp-powerful hp-dark-bg hp-animation hp-animation-trigger'>
                <h3 className='hp-section-title'>Powerful</h3>
                <p className='hp-section-desc'>
                  With over 300 settings, and more being added every release, FullCalendar can do
                  just about anything.
                </p>
                <a className='hp-button hp-button--yellow hp-button--mild-shadow' href='/docs'>
                  Learn more
                </a>
              </div>
              <div className='hp-lightweight hp-neutral-bg hp-animation-trigger'>
                <div className='hp-lightweight__content hp-animation'>
                  <h3 className='hp-section-title'>Lightweight</h3>
                  <p className='hp-section-desc'>
                    Reduce your project's bundle size by using FullCalendar's modular plugins.
                  </p>
                  <a className='hp-button hp-button--blue' href='/docs/plugin-index'>
                    Learn more
                  </a>
                </div>
                <div className='hp-lightweight__sprites'>
                  <div className='hp-lightweight__sprites-inner'>
                    <div className='hp-lightweight__balloon-big hp-animation'>
                      <img src={balloonBig} alt='Amazon' width='196' height='266' />
                    </div>
                    <div className='hp-lightweight__balloon-small1 hp-animation'>
                      <img src={balloonSmall1} alt='Amazon' width='82' height='112' />
                    </div>
                    <div className='hp-lightweight__balloon-small2 hp-animation'>
                      <img src={balloonSmall2} alt='Amazon' width='95' height='127' />
                    </div>
                    <div className='hp-lightweight__balloon-small3 hp-animation'>
                      <img src={balloonSmall3} alt='Amazon' width='83' height='112' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
