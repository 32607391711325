import React, { useEffect } from 'react'
import { BaseLayout } from '@layouts/base'
import { Intro } from '@components/homepage/intro'
import { Space } from '@components/homepage/space'
import { Sky } from '@components/homepage/sky'
import { Mountain } from '@components/homepage/mountain'
import { Ocean } from '@components/homepage/ocean'
import '@styles/homepage/main.scss'

const IndexPage = (): JSX.Element => {
  useEffect(() => {
    const allAnimationTriggers = Array.prototype.slice.call(
      document.querySelectorAll('.hp-animation-trigger')
    )

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('hp-animation-trigger--triggered')
            observer.unobserve(entry.target)
          }
        })
      },
      { threshold: 0.75 }
    )

    allAnimationTriggers.forEach((triggerEl) => {
      observer.observe(triggerEl)
    })
  }, [])

  return (
    <BaseLayout pageIsResponsive footerDark>
      <Intro />
      <Space />
      <Sky />
      <Mountain />
      <Ocean />
    </BaseLayout>
  )
}

export default IndexPage
