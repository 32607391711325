import React from 'react'
import { Link } from 'gatsby'
import octocat from '@assets/homepage/octocat.png'
import narwhalSmall from '@assets/homepage/narwhal-small.png'
import narwhalBig from '@assets/homepage/narwhal-big.png'

export const Ocean = (): JSX.Element => {
  return (
    <section className='hp-ocean-section hp-section'>
      <div className='hp-divider'>
        <div></div>
      </div>
      <div className='hp-section__inner'>
        <div className='hp-section__bg hp-ocean-bg'>
          <div></div>
        </div>
        <div className='hp-section__fg'>
          <div className='container container--always-spacious'>
            <div className='hp-split'>
              <div className='hp-open-source hp-dark-bg hp-animation-trigger'>
                <div className='hp-open-source__content hp-animation'>
                  <h3 className='hp-section-title'>Open Source...</h3>
                  <p className='hp-section-desc'>
                    With over 10 years of open source and over 120 contributors, FullCalendar will
                    always have a free and open source core.
                  </p>
                  <Link className='hp-button hp-button--blue hp-button--mild-shadow' to='/license'>
                    Learn more
                  </Link>
                </div>
                <div className='hp-open-source__sprites'>
                  <div className='hp-open-source__sprites-inner'>
                    <div className='hp-open-source__octocat hp-animation'>
                      <img src={octocat} width='307' height='350' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='hp-premium hp-dark-bg hp-animation-trigger'>
                <div className='hp-premium__content hp-animation'>
                  <h3 className='hp-section-title'>Supercharged.</h3>
                  <p className='hp-section-desc'>
                    Level-up by purchasing premium plugins and support.
                  </p>
                  <Link className='hp-button hp-button--yellow' to='/pricing'>
                    Learn more
                  </Link>
                </div>
                <div className='hp-premium__sprites'>
                  <div className='hp-premium__sprites-inner'>
                    <div className='hp-premium__narwhal-small hp-animation'>
                      <img src={narwhalSmall} width='465' height='416' />
                    </div>
                    <div className='hp-premium__narwhal-big hp-animation'>
                      <img src={narwhalBig} width='762' height='782' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hp-divider'>
        <div></div>
      </div>
    </section>
  )
}
