import React, { useState } from 'react'
import clsx from 'clsx'
import { graphql, useStaticQuery } from 'gatsby'
import { TechSelector } from './techSelector'

const homepageCode: { [key: string]: string } = {
  angular: `npm install @fullcalendar/angular

import { Component } from '@angular/core'
import { CalendarOptions } from '@fullcalendar/angular'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    events: [
      { title: 'event 1', date: '2019-04-01' },
      { title: 'event 2', date: '2019-04-02' }
    ]
  }
}
`,
  js: `npm install @fullcalendar/core
npm install @fullcalendar/daygrid

import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'

let calendar = new Calendar(calendarEl, {
  plugins: [dayGridPlugin],
  initialView: 'dayGridMonth',
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,dayGridWeek'
  }
})

calendar.render()
`,
  react: `npm install @fullcalendar/react
npm install @fullcalendar/daygrid

import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

export const DemoApp = () => (
  <FullCalendar
    plugins={[dayGridPlugin]}
    initialView="dayGridMonth"
  />
)
`,
  vue: `npm install @fullcalendar/vue
npm install @fullcalendar/daygrid

<template>
  <FullCalendar :options="calendarOptions" />
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
  components: { FullCalendar },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth'
      }
    }
  }
`,
}

const codeLineNumbers = Array(18)
  .fill(undefined)
  .map((_val, index) => (
    <span key={index}>
      {index + 1}
      <br />
    </span>
  ))

export const Space: React.FC = () => {
  const [tech, setTech] = useState('react')
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          tabletBreakpoint
        }
      }
    }
  `)
  const tabletBreakpoint = data.site.siteMetadata.tabletBreakpoint

  const codeBlocks = Object.keys(homepageCode).map((val) => (
    <div
      className={clsx(
        'hp-space-editor__code',
        val === tech ? 'hp-space-editor__code--selected' : ''
      )}
      key={`code-${val}`}
    >
      <pre>
        <code>{homepageCode[val]}</code>
      </pre>
    </div>
  ))

  return (
    <section className='hp-space-section hp-section hp-dark-bg'>
      <div className='hp-divider hp-skew'>
        <div />
      </div>
      <div className='hp-section__inner'>
        <div className='hp-space-section__color-bg hp-section__bg hp-skew'>
          <div className='hp-space-bg-container container'>
            <div className='hp-space-editor-bg' />
          </div>
        </div>
        <div className='hp-space-section__image-bg hp-section__bg'>
          <div className='hp-space-bg-container container'>
            <div className='hp-space-editor' data-nosnippet>
              <div className='hp-space-editor__line-numbers'>{codeLineNumbers}</div>
              <div className='hp-space-editor__code-area'>{codeBlocks}</div>
            </div>
          </div>
        </div>
        <div className='hp-section__fg'>
          <div className='container container--always-spacious' id='tech-container'>
            <TechSelector
              tabletBreakpoint={tabletBreakpoint}
              tech={tech}
              setTech={(techName) => {
                setTech(techName)
              }}
            />
          </div>
        </div>
      </div>
      <div className='hp-divider hp-skew'>
        <div />
      </div>
    </section>
  )
}
