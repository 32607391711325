import React from 'react'
import { Link } from 'gatsby'

export const Intro: React.FC = () => {
  return (
    <section className='hp-intro-section hp-section'>
      <div className='container'>
        <div className='hp-split'>
          <div className='hp-intro-verbage'>
            <h1>
              The Most Popular
              <strong>
                JavaScript
                <br />
                Calendar
              </strong>
            </h1>
            <div className='hp-intro-verbage__buttons'>
              <a className='hp-button hp-button--blue' href='/docs/getting-started'>
                Get started
              </a>
              <a
                className='hp-intro-verbage__demo-button hp-button hp-button--yellow'
                href='/demos'
              >
                View demos
              </a>
            </div>
          </div>
          <div className='hp-intro-demo'>
            <div className='hp-intro-demo__calendar'></div>
            <div className='hp-intro-demo__buttons'>
              <Link className='hp-intro-demo__demo-button hp-button hp-button--yellow' to='/demos'>
                View demos
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
