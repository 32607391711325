import React from 'react'
import mountain from '@assets/homepage/mountain.svg'
import amazon from '@assets/homepage/logos/amazon.png'
import uber from '@assets/homepage/logos/uber.png'
import netflix from '@assets/homepage/logos/netflix.png'
import intuit from '@assets/homepage/logos/intuit.png'
import samsung from '@assets/homepage/logos/samsung.png'
import yahoo from '@assets/homepage/logos/yahoo.png'
import paypal from '@assets/homepage/logos/paypal.png'
import akamai from '@assets/homepage/logos/akamai.png'
import astrazeneca from '@assets/homepage/logos/astrazeneca.png'
import pfizer from '@assets/homepage/logos/pfizer.png'
import cisco from '@assets/homepage/logos/cisco.png'
import deloitte from '@assets/homepage/logos/deloitte.png'
import accenture from '@assets/homepage/logos/accenture.png'
import nasa from '@assets/homepage/logos/nasa.png'

export const Mountain = (): JSX.Element => {
  return (
    <section className='hp-mountain-section hp-section'>
      <div className='hp-divider hp-skew'>
        <div></div>
      </div>
      <div className='hp-section__inner'>
        <div className='hp-mountain-section__color-bg hp-section__bg hp-skew'></div>
        <div className='hp-mountain-image-bg hp-section__bg'>
          <div className='hp-mountain-image-bg__left'></div>
          <div className='hp-mountain-image-bg__center'>
            <div>
              <img src={mountain} width='2205' height='748' />
            </div>
          </div>
          <div className='hp-mountain-image-bg__right'></div>
        </div>
        <div className='hp-section__fg'>
          <div className='container'>
            <div className='hp-split'>
              <div className='hp-mountain-logos hp-animation hp-animation-trigger'>
                <h3 className='hp-mountain-logos__title'>Some of our customers</h3>
                <div className='hp-mountain-logos__content'>
                  <div>
                    <img
                      src={amazon}
                      alt='Amazon'
                      width='124'
                      height='38'
                      style={{ marginTop: '11%' }}
                    />
                  </div>
                  <div>
                    <img src={uber} alt='Uber' width='109' height='38' />
                  </div>
                  <div>
                    <img
                      src={netflix}
                      alt='Netflix'
                      width='124'
                      height='34'
                      style={{ marginTop: '4%' }}
                    />
                  </div>
                  <div>
                    <img src={intuit} alt='Intuit' width='124' height='36' />
                  </div>
                  <div>
                    <img
                      src={samsung}
                      alt='Samsung'
                      width='124'
                      height='41'
                      style={{ marginTop: '9%' }}
                    />
                  </div>
                  <div>
                    <img
                      src={yahoo}
                      alt='Yahoo'
                      width='124'
                      height='35'
                      style={{ marginTop: '11%' }}
                    />
                  </div>
                  <div>
                    <img
                      src={paypal}
                      alt='PayPal'
                      width='124'
                      height='35'
                      style={{ marginTop: '13%' }}
                    />
                  </div>
                  <div>
                    <img src={akamai} alt='Akamai' width='124' height='51' />
                  </div>
                  <div style={{ width: '37%' }}>
                    <img src={astrazeneca} alt='AstraZeneca' width='211' height='51' />
                  </div>
                  <div>
                    <img src={pfizer} alt='Pfizer' width='120' height='76' />
                  </div>
                  <div>
                    <img src={cisco} alt='Cisco' width='124' height='65' />
                  </div>
                  <div>
                    <img
                      src={deloitte}
                      alt='Deloitte'
                      width='124'
                      height='24'
                      style={{ marginTop: '7%' }}
                    />
                  </div>
                  <div>
                    <img src={accenture} alt='Accenture' width='124' height='34' />
                  </div>
                  <div>
                    <img
                      src={nasa}
                      alt='Nasa'
                      width='108'
                      height='85'
                      style={{ marginTop: '-15%' }}
                    />
                  </div>
                </div>
              </div>
              <div className='hp-mountain-stats hp-animation hp-animation-trigger'>
                <div className='hp-mountain-stats__item'>
                  <h4>
                    Over <strong>2M</strong>
                  </h4>
                  <strong>NPM</strong> downloads per month
                </div>
                <div className='hp-mountain-stats__item'>
                  <h4>
                    Over <strong>70M</strong>
                  </h4>
                  <strong>CDN</strong> downloads per month
                </div>
                <div className='hp-mountain-stats__item'>
                  <h4>
                    Over <strong>17K</strong>
                  </h4>
                  <strong>GitHub</strong> stargazers
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
